export default function (
  val: number | String,
  forceDecimals: boolean = false,
): String {
  return new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: 1,
    minimumFractionDigits: 2,
    style: "decimal",
    trailingZeroDisplay: !forceDecimals ? "stripIfInteger" : "auto",
  }).format(String(val));
}
