<template>
  <div
    class="ml-4 md:ml-0 md:h-32 md:w-full md:mt-6 w-1/5 flex-grow-0 flex-shrink-0 relative"
  >
    <div
      id="carousel"
      ref="carousel"
      class="slim h-full overflow-y-auto md:overflow-y-clip md:overflow-x-auto relative"
    >
      <div
        id="imageList"
        ref="imageList"
        class="md:block md:space-y-0 md:space-x-6 md:right-auto md:bottom-0 absolute top-0 right-2 space-y-2 pb-4 flex flex-col justify-center min-h-full whitespace-nowrap"
      >
        <div
          v-for="(img, id) in images"
          :key="`${id}-img`"
          class="md:inline-block md:h-full aspect-square bg-white rounded border-2 border-boba-grey cursor-pointer flex justify-center items-center text-center p-1"
        >
          <img
            class="md:h-full block mx-auto"
            :src="img"
            :alt="`Product Image ${id + 1}`"
            @click="chooseImage(img)"
          />
        </div>
      </div>
    </div>

    <fade-transition>
      <div
        v-if="showLeftTransition"
        class="absolute top-0 left-0 right-0 h-6 md:right-auto md:w-12 md:h-full bg-gradient-to-b md:bg-gradient-to-r from-boba-sand to-transparent"
      ></div>
    </fade-transition>
    <fade-transition>
      <div
        v-if="showRightTransition"
        class="absolute bottom-0 left-0 right-0 h-6 md:left-auto md:w-12 md:h-full bg-gradient-to-b md:bg-gradient-to-r to-boba-sand from-transparent"
      ></div>
    </fade-transition>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, nextTick, onUnmounted } from "vue";

defineProps<{
  images: string[];
}>();

const emit = defineEmits(["choose"]);
const carousel = ref<HTMLElement | null>(null);
const imageList = ref<HTMLElement | null>(null);
const showLeftTransition = ref(false);
const showRightTransition = ref(true);

const observers = {
  left: null,
  right: null,
};

function chooseImage(img: string) {
  emit("choose", img);
}

onMounted(() => {
  nextTick(() => {
    observers.left = new IntersectionObserver(
      (elements) => {
        showLeftTransition.value = !elements[0].isIntersecting;
      },
      {
        root: carousel.value,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.98,
      },
    );

    observers.right = new IntersectionObserver(
      (elements) => {
        showRightTransition.value = !elements[0].isIntersecting;
      },
      {
        root: carousel.value,
        rootMargin: "0px",
        threshold: 0.99,
      },
    );

    const img = imageList.value.querySelector("& > div:first-child");
    const lastImg = imageList.value.querySelector("& > div:last-child");
    observers.left.observe(img);
    observers.right.observe(lastImg);
  });
});

onUnmounted(() => {
  observers.left.disconnect();
  observers.right.disconnect();
});
</script>
