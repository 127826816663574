import { createApp } from "vue";
import "./style.css";
import "@fortawesome/fontawesome-pro/css/all.css";
import App from "./App.vue";
import router from "@/router";
import { RouterLink } from "vue-router";
import { createPinia } from "pinia";
import LoginComponent from "@/pkg/auth/components/Login.vue";
import FadeTransition from "@/components/util/FadeTransition.vue";

// import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// import { makeBugsnag, useBugsnag } from '@/modules/bugsnag'
// import { createLogger, withBugsnag } from '@/modules/log'
//

import axios from "axios";
import currencyFormat from "@/util/currencyFormat";

axios.defaults.withCredentials = true;

// initialize pinia
const pinia = createPinia();

const app = createApp(App);
if (import.meta.env.VITE_BUGSNAG_API_KEY) {
  // app.use(
  // 	makeBugsnag(
  // 		import.meta.env.VITE_BUGSNAG_API_KEY,
  // 		import.meta.env.VITE_ENV,
  // 	),
  // )
}
// withBugsnag(useBugsnag())
// app.use(createLogger({ env: import.meta.env.VITE_ENV }))
app.use(router);
app.use(pinia); // add pinia to app
// app.component("fa", FontAwesomeIcon);
app.component("router-link", RouterLink);
app.component('Login', LoginComponent);
app.component('FadeTransition', FadeTransition);
app.use({
  install: (app) => {
    app.config.globalProperties.$format = currencyFormat;
    app.provide("format", currencyFormat);
  },
});

app.mount("#app");
