<template>
  <countdown
    :to="props.to"
    @ended="emit('ended')"
    v-slot="{ formattedCountdown }"
  >
    <slot name="default" :formatted="formattedCountdown">
      <div
        v-bind="$attrs"
        class="isolate text-right font-digi text-white bg-boba-red md:py-3 md:px-6 px-2 py-1 rounded text-lg md:text-3xl -mt-8 md:-mt-14 shadow-lg"
      >
        <slot name="label">
          <p
            class="md:flex md:items-center md:justify-start md:space-x-2 space-x-2"
          >
            <span class="md:pb-2 md:inline-block"
              ><i class="fa-solid fa-clock text-base md:text-xl"
            /></span>
            <span>Time Remaining</span>
          </p>
        </slot>

        <fade-transition>
          <p
            v-if="false == formattedCountdown.ended"
            class="text-2xl tracking-widest flex justify-center font-14seg md:justify-end"
          >
            <fade-transition>
              <span
                class="text-sm font-digi"
                v-if="formattedCountdown.symbol"
                :key="`symbol-${formattedCountdown.symbol}`"
                >{{ formattedCountdown.symbol }}</span
              >
              <span
                v-if="formattedCountdown.left"
                :key="`left-${formattedCountdown.left}`"
                >{{ formattedCountdown.left }}</span
              >
            </fade-transition>
            <span v-if="formattedCountdown.left">:</span>
            <fade-transition>
              <span
                v-if="formattedCountdown.right"
                :key="`right-${formattedCountdown.right}`"
              >
                <span v-if="!formattedCountdown.left" class="pr-1"></span>
                {{
                  formattedCountdown.left
                    ? formattedCountdown.right
                    : parseInt(formattedCountdown.right)
                }}</span
              >
            </fade-transition>
            <fade-transition>
              <span
                v-if="formattedCountdown.label"
                :key="`label-${formattedCountdown.label}`"
                class="text-sm font-digi"
                ><span class="pl-1"></span> {{ formattedCountdown.label }}</span
              >
            </fade-transition>
          </p>
          <p v-else>Ended</p>
        </fade-transition>
      </div>
    </slot>
  </countdown>
</template>
<script setup lang="ts">
import Countdown from "@/components/Headless/Countdown.vue";
import { DateTime } from "ts-luxon";
const props = defineProps<{
  to: DateTime;
}>();

const emit = defineEmits(["ended"]);
</script>
