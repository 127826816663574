import View from "@/components/Listing/View.vue";
import Index from "@/components/Listing/Index.vue";
import OAuthCallback from "@/pkg/auth/components/OAuthCallback.vue";
import AccountBid from "@/components/Account/Bid.vue";
import AccountHome from "@/components/Account/Home.vue";

const routes = [
  {
    path: "/auth/callback",
    component: OAuthCallback,
    name: "auth-callback",
  },
  {
    path: "/",
    component: Index,
    name: "listing-index",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/listings/:slug",
    component: View,
    name: "listing",
    meta: {
      requiresAuth: false,
    },
    // children: [
    //   {
    //     path: "week/:week_number",
    //     component: ViewEntries,
    //     name: "week",
    //   },
    // ],
  },
  {
    path: "/account",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        name: 'account-home',
        component: AccountHome,
      },
      {
        path: "bids/:slug/:bid?",
        component: AccountBid,
        name: "bid-history",
      },
    ],
  },
];

export default routes;
