<template>
  <slot
    name="default"
    :formattedCountdown="formattedCountdown"
    :values="{ countdownValues }"
  />
</template>
<script setup lang="ts">
import { DateTime } from "ts-luxon";
import { computed, onUnmounted, onBeforeMount, ref } from "vue";

const props = defineProps<{
  to: DateTime;
}>();

const emit = defineEmits(["ended"]);

let countdownSeconds = ref(0);
let countdownLabel = ref<{
  ended: boolean;
  symbol: string | null;
  left: number | null;
  right: number | null;
  label: string | null;
}>({
  ended: true,
  left: 0,
  right: 0,
  label: "",
});

let countdownValues = ref<{
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}>({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
});

let timer: any;

onBeforeMount(() => {
  calculateCountdown();
});

onUnmounted(() => {
  clearTimeout(timer);
  timer = null;
});

let endFired = false;
function calculateCountdown() {
  let diff = props.to.diff(DateTime.now(), "seconds");
  let diffSeconds = diff.as("seconds");
  countdownSeconds.value = diffSeconds;

  buildCountdown(diffSeconds);

  if (diffSeconds <= 0) {
    if (!endFired) {
      emit("ended");
      endFired = true;
    }

    if (diffSeconds < -3) {
      countdownLabel.value.ended = true;
      countdownLabel.value.left = null;
      countdownLabel.value.right = null;
      countdownLabel.value.label = null;
      countdownLabel.value.symbol = null;
    }

    setTimeout(calculateCountdown, 100);
    return;
  }

  if (endFired) return;

  countdownLabel.value.ended = false;
  endFired = false;


  let countdownInterval = Math.min(diffSeconds - 3600, 30);
  if (diffSeconds <= 3660) {
    countdownInterval = 1;
  }

  // console.log('updating countdown in..', countdownInterval); 

  timer = setTimeout(calculateCountdown, countdownInterval * 1000);
}

function buildCountdown(diffSeconds: number) {
  if (diffSeconds > 3600 * 24) {
    countdownValues.value.days = Math.floor(diffSeconds / (3600 * 24)); // days

    countdownLabel.value.left = null;
    countdownLabel.value.right = Math.ceil(diffSeconds / (3600 * 24)); // days
    if (diffSeconds / (3600 * 24) > countdownLabel.value.right) {
      countdownLabel.value.symbol = "more than ";
    } else {
      countdownLabel.value.symbol = "almost ";
    }
    //Math.floor((diffSeconds % (3600 * 24)) / 3600); // hours
    countdownLabel.value.label =
      "day" + (countdownLabel.value.right != 1 ? "s" : " ");

    return;
  }

  if (diffSeconds > 3600) {
    countdownValues.value.hours = Math.floor(
      (diffSeconds % (3600 * 24)) / 3600,
    ); // hours

    countdownLabel.value.left = null; // hours
    countdownLabel.value.right = Math.round(diffSeconds / 3600); // hours
    if (diffSeconds / 3600 > countdownLabel.value.right) {
      countdownLabel.value.symbol = "more than ";
    } else {
      countdownLabel.value.symbol = "almost ";
    }
    // countdownLabel.value.right = Math.floor((diffSeconds % 3600) / 60); // minutes
    countdownLabel.value.label =
      "hour" + (countdownLabel.value.right != 1 ? "s" : " ");

    return;
  }

  countdownLabel.value.symbol = null;

  if (diffSeconds >= 60) {
    countdownValues.value.minutes = Math.floor((diffSeconds % 3600) / 60); // minutes

    countdownLabel.value.left = Math.floor(diffSeconds / 60); // minutes
    countdownLabel.value.right = Math.floor(diffSeconds % 60); // seconds
    countdownLabel.value.label = null;
    // "minute" + (countdownLabel.value.left != 1 ? "s" : "");
    return;
  }

  countdownValues.value.seconds = Math.floor(diffSeconds % 60); // seconds

  countdownLabel.value.left = 0; // seconds
  countdownLabel.value.right = Math.max(0, Math.floor(diffSeconds)); // seconds
  countdownLabel.value.label = null; //"second" + (diffSeconds != 1 ? "s" : " ");
}

const formattedCountdown = computed(() => {
  return {
    symbol: countdownLabel.value.symbol,
    ended: countdownLabel.value.ended,
    left: countdownLabel.value.left?.toString().padStart(2, "0") ?? null,
    right: countdownLabel.value.right?.toString().padStart(2, "0") ?? null,
    label: countdownLabel.value.label,
  };
});
</script>
