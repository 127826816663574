<template>
  <BobaLayout>
    <HeadlessIndex
      class="mt-32 md:grid grid-cols-2 lg:grid-cols-4 gap-8 mx-auto max-w-[1800px]"
      :state="auctionState"
    >
      <template #listing="{ listing, route }">
        <div
          class="bg-white rounded-lg p-6 tracking-tighter h-full shadow flex flex-col"
        >
          <p
            v-if="listing.subtitle"
            class="text-lg text-boba-grey-dark leading-none"
          >
            {{ listing.subtitle }}
          </p>
          <p class="text-2xl">{{ listing.title }}</p>
          <div
            v-if="listing.images.length > 0"
            class="bg-white rounded-lg overflow-hidden mt-4"
          >
            <img :src="listing.images[0]" class="mx-auto" />
          </div>
          <div
            v-else
            class="mt-4 bg-boba-grey/15 flex justify-center items-center aspect-square rounded-lg overflow-hidden"
          >
            <p>
              <i class="fa-solid fa-image text-4xl rounded-lg text-boba-grey" />
            </p>
          </div>

          <p class="flex-grow flex-shrink"></p>

          <div
            v-if="!listing.hasEnded()"
            class="text-right text-lg text-boba-grey-dark"
          >
            <p class="inline">
              <span v-if="!listing.isActive()"> Begins in </span>
              <span v-else> Ends in </span>
            </p>
            <countdown
              :to="countdownTime(listing)"
              v-slot="{ formattedCountdown }"
            >
              <span class="text-right">
                <span v-if="formattedCountdown.symbol">{{
                  formattedCountdown.symbol
                }}</span>
                <span v-if="formattedCountdown.left">{{
                  formattedCountdown.left
                }}</span>
                <span v-if="formattedCountdown.left">:</span>
                <span v-if="formattedCountdown.right"
                  >{{
                    formattedCountdown.left
                      ? formattedCountdown.right
                      : parseInt(formattedCountdown.right)
                  }}
                </span>
                <span v-if="formattedCountdown.label">
                  {{ " " + formattedCountdown.label }}</span
                >
              </span>
            </countdown>
          </div>

          <p>
            <button
              @click="navigateTo(route)"
              class="block w-full rounded-lg text-center text-lg bg-boba-green text-boba-brown py-2 px-4"
            >
              View Listing
            </button>
          </p>
        </div>
      </template>
      <template #empty="">
        <div class="text-xl font-boba-brown/50 italic">
          No auctions found.
        </div>
      </template>
    </HeadlessIndex>

    <div  class="mt-10">
      <a v-if="currentTab == 'active'"
        href="?tab=ended" class="px-6 py-3 rounded-lg border-2 border-boba-brown/20 text-lg">
        View complete auctions
      </a> 
      <a v-else-if="currentTab == 'ended'"
        href="?tab=active" class="px-6 py-3 rounded-lg border-2 border-boba-brown/20 text-lg">
        View active auctions
      </a> 
    </div>
  </BobaLayout>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import BobaLayout from "@/components/Layouts/BoBA.vue";
import HeadlessIndex from "@/components/Headless/Index.vue";
import { RouteLocationResolved, useRouter, useRoute } from "vue-router";
import { Listing } from "@/types";

import Countdown from "@/components/Headless/Countdown.vue";

const router = useRouter();
const route = useRoute();

const availTabs = ['active', 'ended'];
const currentTab = ref("active");

if (route.query.tab) {
  let tab = route.query.tab as string;

  if (availTabs.indexOf(tab) > -1) {
    currentTab.value = tab;
  }
}

const auctionState = computed(() => {
  return currentTab.value === "active" ? ["pending", "active"] : ["ended"];
});

function navigateTo(route: RouteLocationResolved) {
  router.push(route);
}

function countdownTime(listing: Listing) {
  return listing.isActive() ? listing.endsAt() : listing.startsAt();
}
</script>
