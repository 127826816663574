import { DateTime } from "ts-luxon";

interface ListingData {
  id: number;
  slug: string;
  title: string;
  subtitle: string;
  description: string;
  short_description: string;
  starts_at: string;
  ends_at: string;
  buy_limit: number;
  buy_limit_scope: string;
  user_buy_limit?: null | number;
  current_price: {
    amount: number;
    started_at: string;
    ended_at: string;
  };
  images: string[];
}

export class BaseClass<T> {
  public data: T;

  constructor(data: any) {
    this.data = data;
    return makeProxy(this);
  }
}

function makeProxy(obj: any) {
  return new Proxy(obj, {
    get(target, prop) {
      if (prop in target) {
        let t = target[prop];

        if (typeof t === "function") {
          return (...args: any) => {
            return t.apply(target, args);
          };
        }

        return t;
      }

      return target.data[prop];
    },
  });
}

export class Listing extends BaseClass<ListingData> {
  timeRemaining() {}

  isActive() {
    const starts = this.startsAt() 
      const ends = this.endsAt()
    
    return starts < new Date() && ends && ends > new Date();
  }

  hasEnded() {
    const ended = this.startsAt() < new Date() && ! this.endsAt()
    return ended
  }

  startsAt() {
    return DateTime.fromISO(this.data.starts_at);
  }

  endsAt() {
    if (this.data.current_price) {
      return DateTime.fromISO(this.data.current_price.ended_at);
    }

    return null;
  }
}

export interface Bid {
  amount: number;
  quantity: number;
}

export * as Errors from "./errors";
