<template>
  <div>
    <slot v-if="!listing" name="loading" :slug="props.listingSlug">
      <div class="flex justify-center items-center h-64">
        <div
          class="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-slate-300"
        ></div>
      </div>
    </slot>
    <slot
      v-if="listing"
      name="default"
      :onBid="initiateBid"
      :listing="listing"
      :countdownTo="countdownTo"
      :reloadListing="loadListing"
    />
    <slot
      v-if="listing"
      name="bid-modal"
      :confirmBid="confirmBid"
      :cancelBid="cancelBid"
      :listing="listing"
    >
      <dialog
        id="bid-dialog"
        class="mx-auto w-full lg:max-w-2xl rounded-lg shadow-lg"
      >
        <h1 class="text-lg font-semibold p-4">Complete Purchase?</h1>
        <p class="px-4">
          {{ $format(totalPrice / 1e9) }} miles will be deducted from your
          account immediately.
        </p>
        <div class="m-4 grid grid-cols-[3fr,1fr,1fr]">
          <p class="p-2 pl-4 font-semibold bg-boba-sand-light rounded-l-lg">
            Item
          </p>
          <p class="p-2 font-semibold bg-boba-sand-light text-right">
            Quantity
          </p>
          <p
            class="p-2 pr-4 font-semibold text-right bg-boba-sand-light rounded-r-lg"
          >
            Price
          </p>

          <p class="ml-2 p-2 pb-0 md:pb-2 pl-0 col-span-3 md:col-span-1">
            <span v-if="listing.subtitle">{{ listing.subtitle }}: </span>
            <span>{{ listing.title }}</span>
          </p>
          <p class="p-2 pt-0 md:pt-2 col-start-2 text-right">
            {{ currentBid.quantity }}x
          </p>
          <p class="mr-2 p-2 pt-0 md:pt-2 pr-0 text-right">
            {{ $format(currentBid.amount / 1e9) }} miles
          </p>

          <p
            class="ml-2 p-2 col-span-2 text-right font-semibold border-t-2 border-slate-300"
          >
            Total
          </p>
          <p class="mr-2 py-2 text-right border-t-2 border-slate-300">
            {{ $format(totalPrice / 1e9) }} miles
          </p>
        </div>

        <div class="flex justify-end px-4 pb-4">
          <button
            @click="cancelBid"
            class="px-4 py-2 mr-4 border enabled:border-boba-sand rounded-lg enabled:shadow disabled:bg-gray-100 disabled:text-gray-400"
            :disabled="states.bidPosting"
          >
            Cancel
          </button>

          <button
            @click="confirmBid"
            class="enabled:bg-boba-brown enabled:text-white px-4 py-2 rounded-lg enabled:shadow disabled:bg-boba-brown/90 disabled:text-gray-200"
            :disabled="states.bidPosting"
          >
            <span v-if="!states.bidPosting">Complete Purchase</span>
            <span v-else
              >Processing <i class="fa-solid fa-spinner fa-spin-pulse"
            /></span>
          </button>
        </div>
      </dialog>
    </slot>
    <slot
      v-if="listing"
      name="thanks-dialog"
      :close="closeThanksModal"
      :listing="listing"
    >
      <dialog
        id="thanks-dialog"
        class="mx-auto w-full lg:max-w-2xl rounded-lg shadow-lg"
      >
        <h1 class="text-lg font-semibold p-4">Purchase Complete!</h1>
        <p class="px-4">
          You will receive a confirmaton email with details about your purchase
          momentarily.
        </p>

        <div class="mt-4 flex justify-end px-4 pb-4">
          <button
            @click="closeThanksModal"
            class="bg-boba-brown text-white px-4 py-2 rounded-lg shadow"
          >
            Close
          </button>
        </div>
      </dialog>
    </slot>
  </div>
</template>
<script lang="ts" setup>
import {
  computed,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import { Bid, Listing } from "@/types";
import { useListingStore } from "@/stores/listing";
import { useAuth } from "@/pkg/auth";
import { DateTime } from "ts-luxon";
import { EventHandler } from "../../util/event-bus";

const authStore = useAuth();
const listingStore = useListingStore();
const props = defineProps<{
  listingSlug: string;
}>();
const emit = defineEmits([
  "confirmBid",
  "confirmedBid",
  "bidSuccessful",
  "bidCancelled",
  "bidFailed",
  "thanksOpened",
  "thanksClosed",
]);
const currentBid = ref<Bid>({ amount: 0, quantity: 0 });
const listing = ref<Listing | null>(null);
let countdownTo = ref<DateTime>(DateTime.now());
let listingTimer: any;
let states = reactive({
  confirmingBid: false,
  bidPosting: false,
  bidComplete: false,
  viewingThanks: false,
});

const totalPrice = computed(() => {
  return currentBid.value.amount * currentBid.value.quantity;
});

let eventHandlers: {
  onAuth?: EventHandler;
  onAuthChange?: EventHandler;
} = {};
onBeforeMount(() => {
  loadListing().then(watchListing);
});

onMounted(() => {
  eventHandlers = {
    onAuth: authStore.events.onAuth(() => {
      // console.log("auth event");
      loadListing();
    }),
    onAuthChange: authStore.events.onAuthChange(() => {
      // console.log("auth change event");
      loadListing();
    }),
  };
});

onBeforeUnmount(() => {
  unwatchListing();
  authStore.events.bus.off(eventHandlers.onAuth);
  authStore.events.bus.off(eventHandlers.onAuthChange);
});

async function loadListing(): Promise<Listing> {
  let l = await listingStore.getListing(props.listingSlug);
  listing.value = l;

  if (l.current_price) {
    countdownTo.value = DateTime.fromISO(l.current_price.ended_at);
  } else if (DateTime.fromISO(l.starts_at) > DateTime.now()) {
    countdownTo.value = DateTime.fromISO(l.starts_at);
  } else {
    const now = DateTime.now();


    if (now != countdownTo.value) {
      countdownTo.value = DateTime.now();
    }
  }

  return l;
}

function watchListing(listing: Listing) {
  // if ( ! listing.current_price) {
  //   // listing doesn't have a current price. could mean it hasn't started, or it's ended.
  //   
  // }
  //
  // if (
  //   !listing.current_price &&
  //   DateTime.fromISO(listing.starts_at) > DateTime.now()
  // ) {
  //   return;
  // }

  if (listingTimer) {
    return false;
  }

  let interval = calculateListingRefreshInterval(listing);

  interval = Math.min(interval, MAX_REFRESH_INTERVAL); // this should never be more than 30s;

  // console.log('refreshing listing in', interval);

  listingTimer = setTimeout(() => {
    listingTimer = null;
    loadListing().then(watchListing);
  }, interval * 1000);
}

function unwatchListing() {
  clearTimeout(listingTimer);
  listingTimer = null;
}

const MAX_REFRESH_INTERVAL = 30;
function calculateListingRefreshInterval(listing: Listing): number {
  let countdownTo = DateTime.fromISO(listing.starts_at);
  if (DateTime.now() > countdownTo) {
    if (listing.current_price) {
      countdownTo = DateTime.fromISO(listing.current_price.ended_at);
    } else {
      return MAX_REFRESH_INTERVAL;
    }
  }

  let diff = countdownTo.diffNow("seconds");
  let diffSeconds = Math.abs(diff.as("seconds"));

  if (diffSeconds < 30) {
    return 1;
  } else if (diffSeconds < 120) {
    return 2;
  } else if (diffSeconds < 300) {
    return 7;
  }

  return MAX_REFRESH_INTERVAL;
}

function showModal(id: string) {
  const el = document.querySelector(`#${id}`);
  el?.showModal();
  attachDialogEvents(el);
}

function hideModal(id: string) {
  const el = document.querySelector(`#${id}`);
  el?.close();
  detachDialogEvents(el);
}

function attachDialogEvents(el: Element) {
  if (!el) {
    return;
  }

  el.addEventListener("close", handleClose);
}

function detachDialogEvents(el: Element) {
  if (!el) {
    return;
  }

  el.removeEventListener("close", handleClose);
}

function handleClose() {
  if (states.confirmingBid) {
    states.confirmingBid = false;
  } else if (states.viewingThanks) {
    states.viewingThanks = false;
    closeThanksModal();
  }
}

function initiateBid(bidAmount: number, bidQty: number) {
  states.confirmingBid = true;
  currentBid.value = { amount: bidAmount, quantity: bidQty };
  showModal("bid-dialog");
}

async function confirmBid() {
  if (states.bidPosting) {
    return;
  }

  try {
    states.bidComplete = false;
    states.bidPosting = true;
    emit("confirmedBid");
    await listingStore
      .bid(
        listing.value.slug,
        currentBid.value.amount,
        currentBid.value.quantity,
      )
      .then(() => {
        return Promise.all([loadListing(), authStore.fetchBalance()]);
      });

    states.bidComplete = true;
    states.bidPosting = false;
    emit("bidSuccessful");

    showModal("thanks-dialog");
    states.viewingThanks = true;

    emit("thanksOpened");
    hideModal("bid-dialog");
  } catch (error) {
    states.bidPosting = false;
    emit("bidFailed");

    // an unexpected error occurred!
    console.error(error);
  }
}

function cancelBid() {
  states.confirmingBid = false;
  states.bidPosting = false;

  hideModal("bid-dialog");
  emit("bidCancelled");
}

function closeThanksModal() {
  states.viewingThanks = false;
  states.bidComplete = false;

  hideModal("thanks-dialog");
  emit("thanksClosed");
}
</script>
