import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const API_ROOT = import.meta.env.VITE_MARKET_API_ROOT;

export function apiUrl(path: string) {
  return API_ROOT + path.replace(/^\//, "");
}

export function api(axiosOptions: AxiosRequestConfig): Promise<ApiResponse> {
  return handleApiResponse(axios(axiosOptions));
}

export async function handleApiResponse(p: Promise<any>): Promise<ApiResponse> {
  return p.then((response: AxiosResponse): ApiResponse => {
    if (response.status >= 200 && response.status < 300) {
      return {
        success: response.data.data?.success ?? true,
        data: response.data.data ?? response.data,
        response: response,
      } as ApiResponse;
    } else {
      return {
        success: false,
        data: {},
        response: response,
        error: new ApiError(`An unexpected error occurred`),
      } as ApiResponse;
    }
  });
}

export interface ApiResponse<T = ApiError> {
  success: boolean;
  data: any;
  response: AxiosResponse;
  error?: T | ApiError;
}

export class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ApiError";
  }
}

export class ValidationError extends ApiError {
  errors: ValidationErrors = {};

  constructor(message: string, errors: ValidationErrors) {
    super(message);
    this.errors = errors;
  }
}

export interface ValidationErrors {
  [field: string]: string[];
}

export const isObject = (thing: any) =>
  thing !== null && typeof thing === "object";

export function isSuccessful(response: AxiosResponse): boolean {
  return response.status >= 200 && response.status < 300;
}

export const isValidationError = (payload: any): payload is ValidationError =>
  isObject(payload) && !!payload.message && !!payload.errors;

export const isApiError = (payload: any): payload is ApiError =>
  isObject(payload) && !(undefined == payload.success) && !!payload.message;
