<template>
  <Login>
    <template #fallback="{ login, register, loginURI }">
      <slot name="fallback" 
        :login="login" 
        :register="register" 
        :loginURI="loginURI" 
      />
    </template>
    <template #default>
      <slot v-if="listing" name="default" :listing="listing" />
      <slot v-else name="loading">
        <p>Loading</p>
      </slot>
    </template>
  </Login>
</template>
<script lang="ts" setup>
import { useAuth } from "@/pkg/auth";
import { useListingStore } from "@/stores/listing";
import { onMounted, ref } from "vue";

const props = defineProps<{
  listingSlug: string;
  bidId: string;
}>();

const listingStore = useListingStore();
const auth = useAuth();

let listing = ref(null);

onMounted(() => {
  auth.events.onAuth(loadBids);
});

async function loadBids() {
  try {
    let response = await listingStore.getBids(props.listingSlug);
    listing.value = response;
  } catch (e) {
    console.error(e);
  }
}
</script>
