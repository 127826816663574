import { api, ApiResponse, apiUrl, appUrl } from "../util";
import { Errors } from "../types";
import axios, { AxiosResponse } from "axios";

// export async function loginUri(returnTo: string): Promise<ApiResponse> {
//   return api({
//     url: apiUrl("/api/login/redirect"),
//     method: "POST",
//     data: {
//       return_to: returnTo,
//       callback: appUrl("/auth/callback"),
//     },
//   }).catch((error) => {
//     throw new Errors.LoginError(
//       `Couldn't fetch login URI: ${error.name}: ${error.message}`,
//     );
//   });
// }

export function loginUri(returnTo: string): string {
  if (returnTo.indexOf("?") == -1) {
    returnTo += "?logged-in";
  }

  return "/auth/login?return_to=" + returnTo;
}

export async function auth(): Promise<ApiResponse> {
  return axios({
    url: appUrl("/auth"),
    method: "GET",
  })
    .then((response: AxiosResponse) => {
      if (response.status == 200) {
        return {
          success: true,
          data: response.data,
          response: response,
        } as ApiResponse;
      }

      return {
        success: false,
        data: response.data,
        response,
        error: new Errors.ApiError(`User could not be authenticated`),
      } as ApiResponse;
    })
    .catch((error) => {
      console.error(error);

      return {
        success: false,
        data: null,
        response: null,
        error: new Errors.ApiError(error.message),
      } as ApiResponse;
    });
}

// export async function oauthCallback(
//   code: string,
//   state: string,
// ): Promise<ApiResponse> {
//   return api({
//     url: apiUrl("/api/login/auth"),
//     method: "POST",
//     data: {
//       code,
//       state,
//       callback: appUrl("/auth/callback"),
//       "set-cookie": true,
//     },
//   }).catch((error) => {
//     throw new Errors.LoginError(
//       `Couldn't fetch login URI: ${error.name}: ${error.message}`,
//     );
//   });
// }

export async function account(): Promise<ApiResponse> {
  return api({
    url: apiUrl("/api/whoami"),
  }).catch((error) => {
    throw new Errors.LoginError("User is not logged in");
  });
}

export async function logout(): Promise<ApiResponse> {
  return api({
    url: "/logout",
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  });
}

export async function balance(): Promise<ApiResponse> {
  return api({
    url: apiUrl("/api/account/balance"),
  });
}
