<template>
  <div>
    <slot name="default" :listings="listings">
      <div v-for="listing in listings" :key="`listing-${listing.id}`">
        <slot
          name="listing"
          :listing="listing"
          :route="
            router.resolve({ name: 'listing', params: { slug: listing.slug } })
          "
        >
          <img :src="listing.images[0]" />
          <p>{{ listing.subtitle }}</p>
          <p>{{ listing.title }}</p>
        </slot>
      </div>
    </slot>
    <slot name="empty" v-if="listings.length == 0" />
  </div>
</template>
<script setup lang="ts">
import { useListingStore } from "@/stores/listing";
import { Listing } from "@/types";
import { onBeforeMount, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";

const props = withDefaults(defineProps<{
  state: string[]
}>(),{
  state: ['pending','active'],
});

const router = useRouter();
const GROUP = import.meta.env.VITE_LISTING_GROUP;
const listingStore = useListingStore();

const listings = ref<Listing[]>([]);
let watchTimeout: any = null;

onBeforeMount(() => {
  loadListings().then(watchListings);
});

onUnmounted(() => {
  clearTimeout(watchTimeout);
  watchTimeout = null;
});

async function loadListings() {
  return listingStore.getListings(GROUP, props.state).then((l) => {
    listings.value = l;
    return l;
  });
}

function watchListings() {
  watchTimeout = setTimeout(
    () => loadListings().then(watchListings), 
    10000
  );
}
</script>
