import { defineStore } from "pinia";
import { listing } from "@/api";
import { Listing, Errors } from "@/types";

export const useListingStore = defineStore("listingStore", () => {
  function init() {
    return {
      getListings,
      getListing,
      bid,
      getBids,
      getAccountListings,
    };
  }

  async function getListings(group: string, state: string[]) {
    let apiResponse = await listing.list(group, state);

    if (apiResponse.success) {
      return apiResponse.data.map((listing: any) => new Listing(listing));
    }

    return null;
  }

  async function getListing(slug: string) {
    let apiResponse = await listing.view(slug);

    if (apiResponse.success) {
      return new Listing(apiResponse.data);
    }

    return null;
  }

  async function bid(slug: string, amount: number, quantity: number) {
    let apiResponse = await listing.bid(slug, amount, quantity);

    if (apiResponse.success) {
      return apiResponse.data;
    }

    throw new Errors.BidError(
      apiResponse.error.message ?? "An unexpected error occurred",
    );
  }

  async function getBids(slug: string) {
    let apiResponse = await listing.getBids(slug);

    if (apiResponse.success) {
      return new Listing(apiResponse.data);
    }

    throw new Errors.NotFoundError(
      apiResponse.error.message ?? "The requested bid does not exist.",
    );
  }

  async function getAccountListings() {
    let apiResponse = await listing.getListings();

    if (apiResponse.success) {
      return apiResponse.data.map((listing: any) => new Listing(listing));
    }

    throw new Errors.NotFoundError(
      apiResponse.error.message ?? "Could not access account listings.",
    );
  }

  return init();
});
