<template>
  <router-view
    id="app-main"
    class=""
    :key="route.fullPath.replace(/(#.*)$/, '')"
  ></router-view>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router";
const route = useRoute();
</script>
