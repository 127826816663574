import { defineStore } from "pinia";
import { ref } from "vue";
import * as api from "./api";
import { User } from "./types";
import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from "axios";
import { DateTime } from "ts-luxon";
import { apiUrl } from "../../api/util";
import { EventBus } from "../../util/event-bus";

let authToken: string | null = null;
let tokenExpiresAt: DateTime | null = null;
let tokenChecked: DateTime | null = null;
let tokenCheckInFlight: boolean = false;
let interceptor: number | null = null;
let interceptorActive: boolean = false;

type InterceptorHandler = (
  config: InternalAxiosRequestConfig<any>,
) => InternalAxiosRequestConfig<any>;

function shouldAddAuthenticationToken(url: string) {
  if (url.startsWith(apiUrl("/"))) {
    return true;
  }

  return false;
}

interface AuthResponse {
  token?: string;
  expires_at?: DateTime;
}

export const useAuth = defineStore("auth", () => {
  let authMonitorInterval: any = null;
  let balanceMonitorInterval: any = null;
  const eventBus = new EventBus();

  const user = ref<User | null>(null);
  const loggedIn = ref(false);
  const balance = ref<{
    available: number;
  }>({
    available: 0,
  });

  function init() {
    return {
      //actions
      check,
      fetchBalance,
      loginUri,
      account,
      monitorLogin,
      monitorBalance,
      stopMonitoring,
      events: { bus: eventBus, onAuthChange, onAuth },
      // handleOAuthCallback,

      // getters
      user,
      loggedIn,
      balance,
    };
  }

  async function checkTokenStatus(): Promise<AuthResponse> {
    let authResponse = await api.auth();
    if (authResponse.success) {
      tokenChecked = DateTime.now();
      return {
        token: authResponse.data.token,
        expires_at: DateTime.fromISO(authResponse.data.expires_at),
      } as AuthResponse;
    }

    return {};
  }

  let checkCount = 0;
  async function check(): Promise<boolean> {
    const currentLogin = loggedIn.value;
    let emit = false;
    if (checkCount == 0) {
      emit = true;
    }

    checkCount++;
    let authResponse = await checkTokenStatus();

    if (undefined == authResponse.token) {
      if (loggedIn.value != currentLogin) {
        if (emit) {
          // console.log(
          //   "emitting auth change not logged in",
          //   false,
          //   currentLogin,
          // );
          eventBus.emit("authChange", false);
        }
      }

      loggedIn.value = false;
      user.value = null;
      balance.value.available = 0;
      checkCount--;
      return false;
    }

    authToken = authResponse.token;
    tokenExpiresAt = authResponse.expires_at!;
    attachAxiosInterceptor();

    await account();
    let tmpLoggedIn = user.value !== null;

    if (tmpLoggedIn != currentLogin) {
      if (emit) {
        // console.log(
        //   "emitting auth change logged in",
        //   tmpLoggedIn,
        //   currentLogin,
        // );
        eventBus.emit("authChange", tmpLoggedIn);
      }
    }

    loggedIn.value = tmpLoggedIn;
    checkCount--;

    return true;
  }

  async function account() {
    try {
      let apiResponse = await api.account();

      if (apiResponse.success) {
        user.value = new User(apiResponse.data);
        return user;
      }
    } catch (error) {
      //
    }

    return false;
  }

  function loginUri(returnTo: string): string {
    return api.loginUri(returnTo);
  }

  async function fetchBalance() {
    let apiResponse = await api.balance();

    if (apiResponse.success) {
      let total = apiResponse.data.balance.reduce(
        (amt: number, currency: { slug: string; available: number }) => {
          if (!(currency.slug == "vbpx" || currency.slug == "bpx")) {
            return amt;
          }

          return amt + currency.available;
        },
        0,
      );

      balance.value = {
        available: total,
      };
    }
  }

  function onAuthChange(callback: (loggedIn: boolean) => void) {
    return eventBus.on("authChange", callback);
  }

  function onAuth(callback: (loggedIn: boolean) => void) {
    if (loggedIn.value) {
      callback(loggedIn.value);
    }

    return onAuthChange((authed) => {
      if (authed) {
        callback(authed);
      }
    });
  }

  function monitorLogin() {
    if (!authMonitorInterval) {
      authMonitorInterval = setInterval(check, 1000 * 60 * 5); // check auth token status every 5 minutes
    }
  }

  function monitorBalance() {
    if (!balanceMonitorInterval) {
      balanceMonitorInterval = setInterval(fetchBalance, 1000 * 30); // check balance every 30 seconds
    }
  }

  function stopMonitoring() {
    clearInterval(authMonitorInterval);
    clearInterval(balanceMonitorInterval);
    authMonitorInterval = null;
    balanceMonitorInterval = null;
  }

  function attachAxiosInterceptor(): void {
    if (!interceptor) {
      interceptor = axios.interceptors.request.use(makeAxiosInterceptor());
    }

    interceptorActive = true;
  }

  function detatchAxiosInterceptor(): void {
    if (interceptor) {
      axios.interceptors.request.eject(interceptor);
      interceptor = null;
      interceptorActive = false;
    }
  }

  function makeAxiosInterceptor(): InterceptorHandler {
    return (
      config: InternalAxiosRequestConfig<any>,
    ): InternalAxiosRequestConfig<any> => {
      if (false == interceptorActive) {
        return config;
      }

      if (
        false == tokenCheckInFlight &&
        (!tokenChecked ||
          tokenChecked < DateTime.now().minus({ minutes: 5 }) ||
          !tokenExpiresAt ||
          tokenExpiresAt < DateTime.now().plus({ minutes: 5 }))
      ) {
        tokenCheckInFlight = true;
        checkTokenStatus()
          .then((response: boolean | AuthResponse) => {
            // console.log("got token status", response);
            if (!response) {
              // disable the interceptor
              detatchAxiosInterceptor();
            }

            tokenCheckInFlight = false;
          })
          .catch(() => {
            tokenCheckInFlight = false;
          });
      }

      if (shouldAddAuthenticationToken(config.url ?? "")) {
        config.headers.setAuthorization(`Bearer ${authToken}`, true);
      }

      return config;
    };
  }

  return init();
});
