export class BidError extends Error {
  constructor(message: string) {
    super(`An error occurred while placing bid: ${message}`);
    this.name = "BidError";
  }
}

export class NotFoundError extends Error {
  constructor(message: string) {
    super(`The requested resource could not be found: ${message}`);
    this.name = "NotFoundError";
  }
}
