import routes from "@/routes";
import { createRouter, createWebHistory } from "vue-router";
// import { useAuthStore } from "./stores/AuthStore";

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash });
        }

        resolve(savedPosition || { top: 0 });
      }, 500);
    });
    // if (to.hash) {
    // 	return { el: to.hash }
    // }

    // return savedPosition || { top: 0 }

    // return { top: 0 }
  },
});
//
// router.beforeEach((to, from, next) => {
//   const authStore = useAuthStore();
//   if (to.meta.requiresAuth && !authStore.authenticated) {
//     next({ name: "login", query: { redirect_to: to.fullPath } });
//     return;
//   }
//
//   next();
// });
//
export default router;
