import { apiUrl, api } from "../util";

export async function list(group: string, state: string[] = []) {
  return api({
    method: "GET",
    url: apiUrl(`/api/v2/auctions`),
    params: {
      group,
      state,
    },
  });
}

export async function view(slug: string) {
  return api({
    method: "GET",
    url: apiUrl(`/api/v2/auctions/${slug}`),
  });
}

export async function bid(slug: string, amount: number, quantity: number) {
  return api({
    method: "POST",
    url: apiUrl(`/api/v2/auctions/${slug}/bid`),
    data: { amount, quantity },
  });
}

export async function getBids(slug: string) {
  return api({
    method: "GET",
    url: apiUrl(`/api/v2/auctions/${slug}/bids/`),
  });
}

export async function getListings() {
  return api({
    method: "GET",
    url: apiUrl(`/api/v2/auctions`),
  });
}
