export class LoginError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "LoginError";
  }
}

export class ApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ApiError";
  }
}

export class ValidationError extends ApiError {
  errors: ValidationErrors = {};

  constructor(message: string, errors: ValidationErrors) {
    super(message);
    this.errors = errors;
  }
}

export interface ValidationErrors {
  [field: string]: string[];
}
