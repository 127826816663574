<template>
  <Login>
    <template #fallback="{ login, register, loginURI }">
      <slot
        name="fallback"
        :login="login"
        :register="register"
        :loginURI="loginURI"
      />
    </template>
    <template #default="{ user }">
      <slot name="default" :user="user" :listings="listingActivity" />
    </template>
  </Login>
</template>
<script lang="ts" setup>
import { useAuth } from "@/pkg/auth";
import { useListingStore } from "@/stores/listing";
import { onMounted, ref } from "vue";

const auth = useAuth();
const listingStore = useListingStore();

const listingActivity = ref(null);

onMounted(() => {
  auth.events.onAuth(loadAuctionActivity);
});

async function loadAuctionActivity() {
  try {
    let response = await listingStore.getAccountListings();
    listingActivity.value = response;
  } catch (e) {
    console.error(e);
  }
}
</script>
