<template></template>
<script setup lang="ts">
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "../store";

const route = useRoute();
const router = useRouter();
const authStore = useAuth();

await authStore
  .handleOAuthCallback(route.query.code, route.query.state)
  .then((uri) => {
    router.push(uri);
  })
  .catch((error) => {
    alert("Failed to authenticate\n" + error.message);
  });
</script>
