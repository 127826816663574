<template>
  <slot v-if="loggedIn" name="default" :user="user" :balance="balance" />
  <slot v-else name="fallback" 
    :loginURI="loginURI"
    :login="login"
    :register="register"
  />
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount } from "vue";
import { useAuth } from "@/pkg/auth";
import { useRoute } from "vue-router";

const auth = useAuth();

const loggedIn = computed(() => auth.loggedIn);
const user = computed(() => auth.user);
const balance = computed(() => auth.balance);
const route = useRoute();

onBeforeMount(async () => {
  auth.check().then((authed: boolean) => {
    auth.monitorLogin();
    return authed
  }).then((authed: boolean) => {
    if (authed) {
      auth.fetchBalance().then(auth.monitorBalance);
    }
  });
});

onBeforeUnmount(() => {
  auth.stopMonitoring();
});

const loginURI = computed(() => auth.loginUri(route.path));

function login() {
  let uri = auth.loginUri(route.path);
  window!.top!.location!.href = uri;
}

function register() {
  window!.top!.location!.href = 'https://app.blokpax.com/sign-up';
}
</script>
