type eventHandler = (...args: any[]) => void;

class EventHandler {
  public name: string;
  public callback: eventHandler;
  public count: number = 0;
  public limit: number = -1;

  constructor(name: string, callback: eventHandler) {
    this.name = name;
    this.callback = callback;
  }

  handle(bus: EventBus, ...args: any[]) {
    // console.log("Handling event", this.name);
    this.callback(...args);
    this.count += 1;
    if (this.limit > -1 && this.count >= this.limit) {
      this.off(bus);
    }

    return this;
  }

  off(bus: EventBus) {
    bus.off(this.name, this);
    return this;
  }

  once() {
    this.limit = 1;
    return this;
  }

  times(n: number) {
    this.limit = n;
    return this;
  }

  static make(name: string, callback: eventHandler) {
    return new EventHandler(name, callback);
  }
}

class EventBus {
  private events: { [key: string]: EventHandler[] } = {};

  constructor() {
    this.events = {};
  }

  on(eventName: string, fn: eventHandler) {
    if (undefined == this.events[eventName]) {
      this.events[eventName] = [];
    }

    let handler = new EventHandler(eventName, fn);
    this.events[eventName].push(handler);
    return handler;
  }

  once(eventName: string, fn: eventHandler) {
    return this.on(eventName, (...data) => {
      fn(...data);
    }).once();
  }

  off(
    eventName: string | EventHandler,
    eventHandler: EventHandler | null = null,
  ) {
    if (eventName instanceof EventHandler) {
      eventName.off(this);
      return this;
    }

    if (this.events[eventName]) {
      if (null === eventHandler) {
        this.events[eventName] = [];
        return this;
      }

      for (let i = 0; i < this.events[eventName].length; i++) {
        if (this.events[eventName][i] == eventHandler) {
          this.events[eventName].splice(i, 1);
          return this;
        }
      }
    }

    return this;
  }

  emit(eventName: string, ...data: any[]) {
    if (this.events[eventName]) {
      this.events[eventName].forEach((fn) => fn.handle(this, ...data));
    }
  }
}

export { EventBus, EventHandler };
