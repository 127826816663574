<
<template>
  <section id="nav" class="py-2">
    <Login>
      <template #fallback="">
        <div class="mx-4 my-2 md:mx-8">
          <button class="font-medium" @click="login">Login</button>
          <button
            class="ml-4 text-white font-medium bg-boba-red rounded-lg px-4 py-2"
            @click="register"
          >
            Register Now
          </button>
        </div>
      </template>
      <template #default="{ user, balance }">
        <nav-menu :user="user" :balance="balance" />
      </template>
    </Login>
  </section>
</template>
<script setup lang="ts">
import Login from "@/pkg/auth/components/Login.vue";
import Avatar from "@/pkg/auth/components/Avatar.vue";
import NavMenu from "@/components/NavMenu.vue";
import { useAuth } from "../pkg/auth";
import { useRoute } from "vue-router";

const auth = useAuth();
const route = useRoute();

async function login() {
  let uri = auth.loginUri(route.path);
  window!.top!.location!.href = uri;
}

function register() {
  window.alert("register");
}
</script>
