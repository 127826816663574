export * as Errors from "./errors";

export interface UserData {
  id: string;
  username: string;
  email?: string;
  avatar?: string;
}

export class BaseClass<T> {
  public data: T;

  constructor(data: any) {
    this.data = data;
    return makeProxy(this);
  }
}

function makeProxy(obj: any) {
  return new Proxy(obj, {
    get(target, prop) {
      if (prop in target) {
        let t = target[prop];

        if (typeof t === "function") {
          return (...args: any) => {
            return t.apply(target, args);
          };
        }

        return t;
      }

      return target.data[prop];
    },
  });
}

export class User extends BaseClass<UserData> {}
