<template>
  <div class="text-sm flex flex-col min-h-screen">
    <section class="bg-boba-brown relative flex justify-end text-white">
      <div
        id="logo"
        class="absolute -top-5 left-6 hover:-top-2 md:left-8 md:-top-10 md:hover:-top-6 transition-all ease-in-out delay-100"
      >
        <a href="https://bobattlearena.com">
          <img
            src="/img/boba/logo.svg"
            class="w-32 md:w-48 drop-shadow-xl"
            alt="Bo Jackson Battle Arena"
        /></a>
      </div>

      <user-auth />
    </section>

    <div class="px-4 mt-4 md:px-8">
      <slot name="default" />
    </div>

    <div class="flex-grow flex-shrink"></div>

    <section
      class="bg-boba-brown text-white mt-20 pt-8 min-h-80 px-4 md:px-8 py-4"
    >
      <div class="text-right text-4xl my-2 space-x-4 md:space-x-4">
        <a href="https://www.instagram.com/bobattlearena/" target="_blank"
          ><i class="fa-brands fa-instagram"
        /></a>
        <a href="https://www.facebook.com/bobattlearena/" target="_blank"
          ><i class="fa-brands fa-facebook"
        /></a>
        <a href="https://www.youtube.com/@bobattlearena" target="_blank"
          ><i class="fa-brands fa-youtube"
        /></a>
        <a href="https://x.com/bobattlearena" target="_blank"
          ><i class="fa-brands fa-x-twitter"
        /></a>
      </div>

      <div class="h-1 bg-boba-red relative">
        <div class="text-center absolute md:left-1/2 md:-ml-10">
          <img
            src="/img/boba/full-logo.svg"
            class="w-32 mx-auto -mt-14 md:-mt-20"
          />
        </div>
      </div>

      <div
        class="ml-36 md:flex md:justify-between mt-2 md:ml-0 text-right md:text-left"
      >
        <div
          class="md:space-x-1 md:space-y-0 flex flex-col md:block space-y-2 space-x-0"
        >
          <a href="/terms" target="_blank" title="Terms of Service"
            >Terms of Service</a
          >
          <span class="hidden md:inline-block">&middot;</span>

          <a
            href="/privacy"
            target="_blank"
            class="no-brand"
            title="Privacy Policy"
          >
            Privacy Policy</a
          >
          <span class="hidden md:inline-block">&middot;</span>

          <a
            href="https://www.iubenda.com/privacy-policy/33707112/cookie-policy"
            target="_blank"
            class="no-brand"
            title="Cookie Policy"
            >Cookie Policy</a
          >
        </div>

        <div class="text-right md:mt-0 mt-4">
          <p>
            &copy;{{ year }} Bo Jackson Battle Arena<span
              class="align-super text-[.5rem]"
              >tm</span
            >
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script setup lang="ts">
import UserAuth from "@/components/UserAuth.vue";
const year = new Date().getFullYear();
</script>
